import React from 'react';

const DirectionsPage = () => {
    const destination = "The Dodford Inn, Whinfield Rd, Bromsgrove B61 9BG";

    return (
        <div className="p-6 min-h-screen flex items-center flex-col justify-center">
            <h1 className="text-7xl font-bold mb-12 text-[#476a4c]">Directions to The Dodford Inn</h1>
            <p className="font-bold mb-12 text-[#476a4c]">Postcode: B619BG</p>
            <p className="text-center mt-4 text-gray-600">
                <ul>
                    <li className="text-center mt-4 text-gray-600">Approach from the South of the venue using the A448
                        Kidderminster Road.
                    </li>
                    <li className="text-center mt-4 text-gray-600">Turn onto Fockbury Rd (0.3 mi).</li>
                    <li className="text-center mt-4 text-gray-600">Turn left onto Priory Rd (1.0 mi).</li>
                    <li className="text-center mt-4 text-gray-600">Turn right onto Warbage Ln (0.2 mi).</li>
                    <li className="text-center mt-4 text-gray-600">Turn right onto Whinfield Rd (305 ft).</li>
                    <li className="text-center mt-4 text-gray-600">Turn left into The Dodford Inn.</li>
                </ul>
            </p>
        </div>
    );
};

export default DirectionsPage;
