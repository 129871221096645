import React from 'react';

const AccommodationPage = () => {
    // Sample static data for hotels/B&Bs within a 10-mile radius of B61 9BG
    const accommodations = [
        {
            id: 1,
            name: "Bromsgrove Hotel & Spa",
            address: "Birmingham Road, Bromsgrove",
            rating: 4.0,
            distance: "5 miles",
        },
        {
            id: 2,
            name: "Holiday Inn Birmingham Bromsgrove",
            address: "Kidderminster Rd, Bromsgrove",
            rating: 4.2,
            distance: "3.8 miles",
        },
        {
            id: 3,
            name: "Grafton Manor Hotel",
            address: "Grafton Ln, Bromsgrove",
            rating: 4.5,
            distance: "2.5 miles",
        },
        {
            id: 4,
            name: "Ye Olde Black Cross",
            address: "Bromsgrove High St, Bromsgrove",
            rating: 3.9,
            distance: "3.3 miles",
        },
        {
            id: 5,
            name: "Premier Inn Bromsgrove Central",
            address: "Worcester Rd, Bromsgrove",
            rating: 4.1,
            distance: "4.0 miles",
        },
    ];

    return (
        <div className="p-6 min-h-screen flex flex-col items-center justify-center">
            <h1 className="text-7xl font-bold mb-12 text-[#476a4c]">Places Nearby</h1>
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-6xl">
                {accommodations.map((place) => (
                    <li
                        key={place.id}
                        className="flex flex-col justify-between h-60 border border-gray-200 p-6 rounded-lg bg-white shadow"
                    >
                        <div>
                            <h2 className="text-2xl font-semibold text-gray-700">{place.name}</h2>
                            <p className="text-gray-500 mt-2">{place.address}</p>
                        </div>
                        <div className="flex items-center justify-between mt-4">
                            <p className="text-gray-600">Distance: {place.distance}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AccommodationPage;
