import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

function RSVP() {
    const initialFormState = {
        firstName: "",
        lastName: "",
        attending: true,
        dietaryRequirements: "",
        guests: [{ firstName: "", lastName: "", dietaryRequirements: "" }],
    };

    const savedData =
        JSON.parse(localStorage.getItem("rsvpFormData")) || initialFormState;

    if (!savedData.guests || !Array.isArray(savedData.guests)) {
        savedData.guests = [{ firstName: "", lastName: "", dietaryRequirements: "" }];
    }

    const [formData, setFormData] = useState(savedData);
    const [showGuests, setShowGuests] = useState(false); // State to control guest visibility
    const [step, setStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const totalSteps = 2;

    const navigate = useNavigate();

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("rsvpFormData"));
        if (storedData && Array.isArray(storedData.guests)) {
            setFormData(storedData);
        } else {
            setFormData(initialFormState);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("rsvpFormData", JSON.stringify(formData));
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleGuestChange = (index, field, value) => {
        const updatedGuests = formData.guests.map((guest, i) =>
            i === index ? { ...guest, [field]: value } : guest
        );
        setFormData({ ...formData, guests: updatedGuests });
    };

    const addGuest = () => {
        setFormData((prevState) => ({
            ...prevState,
            guests: [...prevState.guests, { firstName: "", lastName: "", dietaryRequirements: "" }],
        }));
    };

    const removeGuest = (index) => {
        const updatedGuests = formData.guests.filter((_, i) => i !== index);
        setFormData({ ...formData, guests: updatedGuests });
    };

    const toggleAttending = () => {
        setFormData((prev) => ({
            ...prev,
            attending: !prev.attending,
        }));
    };

    const handleNext = () => {
        if (step === 1 && (formData.firstName.trim() === "" || formData.lastName.trim() === "")) {
            alert("Please enter your first and last name to proceed.");
            return;
        }
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return;

        if (formData.firstName.trim() === "" || formData.lastName.trim() === "") {
            alert("Please enter your first and last name before submitting.");
            return;
        }

        // Remove empty guests if "showGuests" is unchecked
        const finalFormData = {
            ...formData,
            guests: showGuests
                ? formData.guests.filter(
                    (guest) =>
                        guest.firstName.trim() !== "" || guest.lastName.trim() !== ""
                )
                : [],
        };

        setIsSubmitting(true);

        try {
            await fetch(
                "https://script.google.com/macros/s/AKfycbw4lVUDYtJ_viH2c5MkQzAVbBwebHM3ISOXd2JvjGaT0DfcfzEHbVFcI5zdvjr5nPDsYg/exec",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "no-cors",
                    body: JSON.stringify(finalFormData),
                }
            );

            // Clear localStorage and reset the form
            localStorage.removeItem("rsvpFormData");
            setFormData(initialFormState);
            setStep(1);

            // Redirect to Thank You page
            navigate("/thank-you", { state: { attending: formData.attending } });
        } catch (error) {
            console.error("Error submitting RSVP:", error);
            alert(
                "An error occurred while submitting your RSVP. Please check your network connection or try again later."
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    const progressPercentage = (step / totalSteps) * 100;

    const variants = {
        initial: { opacity: 0, x: 100 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -100 },
    };

    return (
        <div className="flex items-center justify-center flex-col min-h-screen">
            <h1 className="text-7xl font-bold mb-4 text-[#476a4c] mb-[50px]">
                RSVP Details
            </h1>
            <form
                className="w-full max-w-md bg-white p-8 rounded shadow-md text-center"
                noValidate
            >
                {/* Progress Bar */}
                <div className="fixed top-0 w-full left-0 border-0 rounded-0">
                    <div className="overflow-hidden h-2 text-xs flex bg-gray-200">
                        <div
                            style={{ width: `${progressPercentage}%` }}
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#476a4c]"
                        ></div>
                    </div>
                </div>

                {/* Animated Question Content */}
                <AnimatePresence mode="wait">
                    {step === 1 && (
                        <motion.div
                            key="step1"
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.5 }}
                        >
                            <p className="text-sm text-gray-500 mb-4">
                                Question 1 of {totalSteps}
                            </p>
                            <div className="flex gap-x-5">
                                <div>
                                    <label className="block text-[20px] text-left font-bold text-[#476a4c]">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                        className="w-full p-2 border border-gray-300 rounded mb-6"
                                    />
                                </div>
                                <div>
                                    <label className="block text-[20px] text-left font-bold text-[#476a4c]">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                        className="w-full  p-2 border border-gray-300 rounded mb-6"
                                    />
                                </div>
                            </div>
                            <label className="block text-[20px] text-left font-bold text-[#476a4c]">
                                Dietary Requirements
                            </label>
                            <input
                                type="text"
                                name="dietaryRequirements"
                                value={formData.dietaryRequirements}
                                onChange={handleChange}
                                placeholder="e.g., Vegetarian, Vegan, None"
                                className="w-full p-2 border border-gray-300 rounded mb-6"
                            />
                        </motion.div>
                    )}

                    {step === 2 && (
                        <motion.div
                            key="step2"
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.5 }}
                        >
                            <p className="text-sm text-gray-500 mb-4">
                                Question 2 of {totalSteps}
                            </p>
                            <label className="block mb-2 text-[40px] font-bold mb-4 text-[#476a4c]">
                                Attendance
                            </label>
                            <div
                                className="flex items-center justify-center w-full"
                                onClick={toggleAttending}
                            >
                                <div
                                    className={`flex items-center w-[235px] h-12 rounded-full p-1 cursor-pointer relative mb-[50px] ${
                                        formData.attending
                                            ? "bg-[#476a4c]"
                                            : "bg-red-200"
                                    }`}
                                >
                                    <span
                                        className={`absolute text-sm font-medium left-4 z-50 ${
                                            formData.attending
                                                ? "text-white"
                                                : "!text-red-600"
                                        }`}
                                    >
                                        Not Attending
                                    </span>
                                    <span
                                        className={`absolute text-sm font-medium right-[30px] z-50 ${
                                            formData.attending
                                                ? "!text-[#476a4c]"
                                                : "text-white"
                                        }`}
                                    >
                                        Attending
                                    </span>
                                    <div
                                        className={`bg-white w-28 h-10 rounded-full shadow-md transition-transform duration-300 ${
                                            formData.attending
                                                ? "transform translate-x-[100%]"
                                                : "transform translate-x-0"
                                        }`}
                                    ></div>
                                </div>
                            </div>

                            <div className="flex items-center mb-4">
                                <input
                                    type="checkbox"
                                    id="showGuests"
                                    checked={showGuests}
                                    onChange={(e) => setShowGuests(e.target.checked)}
                                    className="mr-2"
                                />
                                <label
                                    htmlFor="showGuests"
                                    className="text-sm text-gray-700"
                                >
                                    Add additional guests?
                                </label>
                            </div>

                            {showGuests && (
                                <div>
                                    <h2 className="text-lg font-bold mt-4 mb-2">
                                        Additional Guests
                                    </h2>
                                    {formData.guests.map((guest, index) => (
                                        <div
                                            key={index}
                                            className="flex flex-col mb-4 gap-y-2"
                                        >
                                            <div className="flex w-full flex-row mb-2 gap-x-5">
                                                <div className="w-1/2">
                                                    <label className="text-left block">
                                                        First Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={guest.firstName}
                                                        onChange={(e) =>
                                                            handleGuestChange(
                                                                index,
                                                                "firstName",
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="First Name"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                    />
                                                </div>

                                                <div className="w-1/2">
                                                    <label className="text-left block">
                                                        Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={guest.lastName}
                                                        onChange={(e) =>
                                                            handleGuestChange(
                                                                index,
                                                                "lastName",
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Last Name"
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                    />
                                                </div>
                                            </div>
                                            <label className="text-left block">
                                                Dietary Requirements
                                            </label>
                                            <input
                                                type="text"
                                                value={guest.dietaryRequirements}
                                                onChange={(e) =>
                                                    handleGuestChange(
                                                        index,
                                                        "dietaryRequirements",
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Dietary Requirements"
                                                className="w-full p-2 border border-gray-300 rounded"
                                            />
                                            {formData.guests.length > 1 && (
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        removeGuest(index)
                                                    }
                                                    className="mt-2 p-2 text-red-500"
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={addGuest}
                                        className="mt-2 text-blue-500"
                                    >
                                        Add Guest
                                    </button>
                                </div>
                            )}
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Navigation Buttons */}
                <div className="flex justify-between mt-4">
                    {step > 1 && (
                        <button
                            type="button"
                            onClick={handleBack}
                            className="p-2 border border-purple-500 text-purple-500 rounded"
                        >
                            Back
                        </button>
                    )}
                    {step < totalSteps ? (
                        <button
                            type="button"
                            onClick={handleNext}
                            className="p-2 right-0 border border-purple-500 text-purple-500 rounded"
                        >
                            Next
                        </button>
                    ) : (
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="p-2 border border-purple-500 text-purple-500 rounded"
                            disabled={isSubmitting}
                        >
                            Submit RSVP
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default RSVP;
